const paymentApiUrl = process.env.REACT_APP_PAYMENT_API_URL;
const profileApiUrl = process.env.REACT_APP_PROFILE_API_URL;
const walletApiUrl = process.env.REACT_APP_WALLET_API_URL;

const buildDefaultHeaders = (accessToken) => {
  return {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${accessToken}`,
  }
}

const sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

export {
  buildDefaultHeaders,
  paymentApiUrl,
  profileApiUrl,
  walletApiUrl,
  sleep,
}
