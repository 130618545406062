import axios from "axios";
import { buildDefaultHeaders, paymentApiUrl } from "./apis";

const axiosClient = axios.create({
  baseURL: paymentApiUrl,
  timeout: 5 * 1000,
});

const setupUserConnectedAccount = async (country, accessToken) => {
  const response = await axiosClient.put("/v1/stripe/connected_account", { country }, {
    headers: buildDefaultHeaders(accessToken),
  });
  const connectedAccount = response.data?.data;
  return connectedAccount;
}

const getUserConnectedAccountVerified = async (accessToken) => {
  const response = await axiosClient.get("/v1/stripe/connected_account/verified", {
    headers: buildDefaultHeaders(accessToken),
  });
  const verified = response.data?.data;
  return verified;
}

const deleteUserConnectedAccount = async (accessToken) => {
  await axiosClient.delete("/v1/stripe/connected_account", {
    headers: buildDefaultHeaders(accessToken),
  });
}

export {
  setupUserConnectedAccount,
  getUserConnectedAccountVerified,
  deleteUserConnectedAccount,
};
