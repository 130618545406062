const Coin = () => {
  return (
    <svg version='1.1' viewBox='0 0 16 16' xmlns='http://www.w3.org/2000/svg' style={{ heigth: "20px", width: "20px" } }>
      <g fill='none' fillRule='evenodd'>
        <circle cx='8' cy='8' r='8' fill='#FFC000'>
        </circle>
        <path d='m11.272 4.0119c-0.36543-0.33732-0.79175-0.5903-1.2837-0.75896-0.49192-0.16866-0.97915-0.25299-1.457-0.25299-0.42633 0-0.84329 0.056219-1.2556 0.16866s-0.78238 0.2811-1.1056 0.50597-0.58562 0.51534-0.78707 0.86203-0.29984 0.75896-0.29984 1.2415c0 0.44507 0.070274 0.81518 0.21082 1.115 0.14055 0.29984 0.32795 0.54814 0.55282 0.75428s0.48255 0.37011 0.76364 0.49192c0.28578 0.12649 0.56688 0.22956 0.85266 0.31858 0.29047 0.098384 0.57156 0.19208 0.8386 0.2811 0.26704 0.089014 0.4966 0.19208 0.69337 0.31389 0.19677 0.12181 0.35137 0.26236 0.46381 0.42633 0.11712 0.16397 0.17334 0.37011 0.17334 0.61841 0 0.25767-0.051534 0.47318-0.1546 0.64652-0.10307 0.17334-0.23425 0.31858-0.39822 0.4357-0.16397 0.11712-0.34669 0.19677-0.55282 0.2483-0.20614 0.046849-0.4029 0.074959-0.59967 0.074959-0.3748 0-0.74959-0.093699-1.1384-0.2811-0.38416-0.1874-0.69337-0.44038-0.92293-0.75896l-1.1993 1.1197c0.3748 0.44507 0.85266 0.78238 1.4383 1.0073 0.58562 0.22488 1.1853 0.342 1.7943 0.342 0.45912 0 0.89951-0.060904 1.3165-0.1874 0.41696-0.12649 0.78238-0.30921 1.0916-0.56219 0.30921-0.2483 0.55751-0.56219 0.74491-0.94167 0.1874-0.37948 0.2811-0.81986 0.2811-1.3258 0-0.46381-0.084329-0.84797-0.25767-1.1619-0.17334-0.30921-0.39353-0.56688-0.66526-0.77301-0.27173-0.20614-0.56688-0.37011-0.89014-0.49192-0.32326-0.12649-0.63715-0.23425-0.93699-0.33263-0.26704-0.079644-0.51066-0.15929-0.73085-0.23893-0.22019-0.079644-0.41227-0.17334-0.56688-0.2811-0.1546-0.10775-0.27641-0.23425-0.36543-0.38885-0.089014-0.14992-0.13118-0.33263-0.13118-0.54814 0-0.22956 0.051534-0.43101 0.1546-0.59499 0.10307-0.16397 0.23425-0.29984 0.39353-0.39822 0.15929-0.10307 0.33732-0.17803 0.53408-0.22488 0.19677-0.046849 0.38885-0.074959 0.58562-0.074959 0.32795 0 0.65589 0.079644 0.97915 0.23425 0.32326 0.1546 0.57625 0.35137 0.74959 0.58093l1.0869-1.2087z' fill='#fff' fillRule='nonzero'>
        </path>
      </g>
    </svg>
  );
}

export default Coin;
