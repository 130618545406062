import { paymentApiUrl } from "../services/apis";

const pageLinks = [
  {
    title: "Worlds",
    url: "https://atlas.sansar.com/",
  },
  {
    title: "Events",
    url: "https://events.sansar.com",
  },
  {
    title: "Store",
    url: "https://store.sansar.com/",
  },
  {
    title: "Download",
    url: "https://www.sansar.com/download",
  },
  {
    title: "Community",
    url: "https://help.sansar.com/hc/en-us/community/topics",
  },
  {
    title: "Help",
    url: "https://help.sansar.com/hc/en-us",
  },
  {
    title: "Discord",
    url: "https://discord.com/invite/sansarofficial",
  },
];

const subMenuLinks = [
  {
    title: "Account",
    url: "https://account.sansar.com/change-password",
  },
  {
    title: "Buy Sansar dollars",
    url: "https://payment.sansar.com",
  },
  {
    title: "Purchases",
    url: "https://store.sansar.com/purchases",
  },
  {
    title: "Transactions",
    url: "https://store.sansar.com/transactions",
  },
  {
    title: "Manage Listings",
    url: "https://store.sansar.com/dashboard",
  },
  {
    title: "Log out",
    url: `${paymentApiUrl}/v1/auth/out`,
  },
];

export { pageLinks, subMenuLinks };
