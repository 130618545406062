import axios from "axios";
import { paymentApiUrl } from "./apis";

const axiosClient = axios.create({
  baseURL: paymentApiUrl,
  timeout: 5 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

const getBundleById = async (bundleId) => {
  const response = await axiosClient.get(`/v1/bundles/${bundleId}`);
  const bundle = response.data?.data;
  return bundle;
}

const getBundles = async () => {
  const response = await axiosClient.get("/v1/bundles");
  const bundles = response.data?.data;
  return bundles;
}

export {
  getBundleById,
  getBundles,
};
