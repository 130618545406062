import Coin from "./Coin";

const BundleItem = ({ amount }) =>  {
  return (
    <div className='bundle-purchase-amount-wrapper'>
      <Coin />
      <div>
        <p className='bundle-purchase-amount'>
          {amount}
        </p>
      </div>
      <div>
        Sansar dollars
      </div>
    </div>
  )
}

export default BundleItem;
