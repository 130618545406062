import React from "react";
import { connect } from "react-redux";
import { withCookies } from "react-cookie";
import { withRouter } from "react-router-dom";
import { getProfile } from "../services/profile";
import { getBalance } from "../services/marketplace";

class AppGlobalSettings extends React.Component {

  componentWillMount() {
    const { dispatch, cookies } = this.props;

    const accessToken = cookies.get("access_token");
    if (accessToken) {
      dispatch({ type: "accessToken/update", payload: accessToken });
      dispatch({ type: "userIsLoggedIn/update", payload: true });

      this.setExtraInfoFromAccessToken(accessToken);
    }

    const refreshToken = cookies.get("refresh_token");
    if (refreshToken) {
      dispatch({ type: "refreshToken/update", payload: refreshToken });
    }
  }

  async setExtraInfoFromAccessToken(accessToken) {
    const { dispatch } = this.props;

    // decode the access token
    const tokenParts = accessToken.split(".");
    if (tokenParts.length === 3) {
      const decoded = JSON.parse(atob(tokenParts[1]));
      dispatch({ type: "userIsFromCognito/update", payload: Boolean(!!decoded.cognito_id) });
    }

    try {
      const [profile, balance] = await Promise.all([
        getProfile(accessToken),
        getBalance(accessToken),
      ]);
      dispatch({ type: "userProfile/update",
        payload: {
          accountId: profile.accountId,
          personaId: profile.avatarId,
          personaHandle: profile.avatarHandle,
          personaName: profile.avatarName,
          avatarImageAssetId: profile.avatarImageAssetId,
          avatarImageUrl: profile.avatarImageUrl,
          level: profile.level,
          roles: profile.staticRoles,
        }
      });
      dispatch({ type: "userBalance/update", payload: Number(balance.balance) });
    } catch (error) {
      // console.error(error.message);
    }
  }

  render() {
    return ""
  }
}

const mapStateToProps = (state) => {
  return {}
};

export default connect(mapStateToProps)(withRouter(withCookies(AppGlobalSettings)));
