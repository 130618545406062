


import { initialState } from "../store/store";

export function UserReducer(state = initialState, action) {
  switch (action.type) {
    // accessToken
    case "accessToken/update": {
      return {
        ...state,
        accessToken: action.payload,
      }
    }
    // refreshToken
    case "refreshToken/update": {
      return {
        ...state,
        refreshToken: action.payload,
      }
    }
    // userProfile
    case "userProfile/update": {
      return {
        ...state,
        userProfile: action.payload,
      }
    }
    // userBalance
    case "userBalance/update": {
      return {
        ...state,
        userBalance: action.payload,
      }
    }
    // userIsFromCognito
    case "userIsFromCognito/update": {
      return {
        ...state,
        userIsFromCognito: action.payload,
      }
    }
    // userIsLoggedIn
    case "userIsLoggedIn/update": {
      return {
        ...state,
        userIsLoggedIn: action.payload,
      }
    }
    default:
      return state
  }
}
