import axios from "axios";
import { buildDefaultHeaders, paymentApiUrl } from "./apis";

const axiosClient = axios.create({
  baseURL: paymentApiUrl,
  timeout: 5 * 1000,
});

const createStripePaymentSession = async (payload, accessToken) => {
  const response = await axiosClient.post("/v1/stripe/payment_session", payload, {
    headers: buildDefaultHeaders(accessToken),
  });
  const session = response.data?.data;
  return session;
}

const createPaypalPaymentSession = async (payload, accessToken) => {
  const response = await axiosClient.post("/v1/paypal/payment_session", payload, {
    headers: buildDefaultHeaders(accessToken),
  });
  const session = response.data?.data;
  return session;
}

const createSteamPaymentSession = async (payload, accessToken) => {
  const response = await axiosClient.post("/v1/steam/payment_session", payload, {
    headers: buildDefaultHeaders(accessToken),
  });
  const session = response.data?.data;
  return session;
}

export {
  createStripePaymentSession,
  createPaypalPaymentSession,
  createSteamPaymentSession,
};
