import React from "react";
import MessageTextPage from "../components/MessageTextPage";

const WithdrawOnboardingComplete = () => {
  return (
    <MessageTextPage
      messageText="Your onboarding was completed. If the verification is successful, you'll be eligible to start receiving payouts."
      pageName="withdraw"
      pageRoute="/withdraw"
    />
  );
};

export default WithdrawOnboardingComplete;
