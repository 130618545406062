import axios from "axios";
import { buildDefaultHeaders, paymentApiUrl } from "./apis";

const axiosClient = axios.create({
  baseURL: paymentApiUrl,
  timeout: 5 * 1000,
});

const getBalance = async (accessToken) => {
  const response = await axiosClient.get("/v1/balance", {
    headers: buildDefaultHeaders(accessToken),
  });
  const balance = response.data?.data;
  return balance;
}

export {
  getBalance,
};
