import axios from "axios";
import { buildDefaultHeaders, paymentApiUrl } from "./apis";

const axiosClient = axios.create({
  baseURL: paymentApiUrl,
  timeout: 5 * 1000,
});

const createWithdrawTransaction = async (payload, accessToken) => {
  const response = await axiosClient.post("/v1/withdraws", payload, {
    headers: buildDefaultHeaders(accessToken),
  });
  const request = response.data?.data;
  return request;
}

const getWithdrawTransactionsProcessing = async (accessToken) => {
  const response = await axiosClient.get("/v1/withdraws/processing", {
    headers: buildDefaultHeaders(accessToken),
  });
  const requests = response.data?.data;
  return requests;
}

export {
  createWithdrawTransaction,
  getWithdrawTransactionsProcessing,
};
