const buildCartItemFromBundle = (bundle, quantity) => {
  const cartItem = {
    id: bundle.id,
    name: bundle.name,
    description: bundle.description,
    price: bundle.price,
    image: bundle.image,
    currency: bundle.currency,
    quantity,
    sansar_dollar_amount: bundle.sansar_dollar_amount,
    formatted_price: bundle.formatted_price,
    steam_id: bundle.steam_id,
  };
  return cartItem;
}

export {
  buildCartItemFromBundle,
};
