import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import Coin from "./Coin";
import { pageLinks, subMenuLinks } from "../utils/headerLinks";
import "../styles/header-desktop.css";
import "../styles/header-mobile.css";

const Header = () => {
  const history = useHistory();

  const userBalance = useSelector((state) => state.user.userBalance); // from redux store
  const userProfile = useSelector((state) => state.user.userProfile); // from redux store
  const userIsLoggedIn = useSelector((state) => state.user.userIsLoggedIn); // from redux store

  const showMobileNavbar = (e) => {
    const mobileNavbar = document.querySelector(".mobile-navbar");
    mobileNavbar.classList.add("mobile-navbar-slide");
  }

  const closeMobileNavbar = (e) => {
    const mobileNavbar = document.querySelector(".mobile-navbar");
    mobileNavbar.classList.remove("mobile-navbar-slide");
  }

  const toggleDesktopDropdown = (e) => {
    const desktopDropdown = document.querySelector(".desktop-navbar-right-dropdown");
    desktopDropdown.style.visibility === "hidden" ?
      desktopDropdown.style.visibility = "visible" :
      desktopDropdown.style.visibility = "hidden";
  }

  const goToBundles = (e) => {
    history.push("/");
  }

  return (
    <header>
      <div className='navbar-logo'>
        <Link to='/'></Link>
      </div>

      <nav className='desktop-navbar'>
        <ul className='desktop-navbar-main-menu'>
          {userIsLoggedIn && pageLinks.map((link) => (
            <li key={link.title}>
              <a href={link.url} className='navbar-link'>
                {link.title}
              </a>
            </li>
          ))}
        </ul>

        {userIsLoggedIn && (
          <div className='desktop-navbar-right'>
            <div className='desktop-navbar-right-balance'>
              <div onClick={ goToBundles }>
                <Coin />
                { (userBalance && userBalance > 0) ? (
                  <p>{ userBalance }</p>
                ) : (
                  <p>Get Sansar dollars</p>
                )}
              </div>
            </div>
            <div className='desktop-navbar-right-profile'>
              <div className='user-profile' onClick={ toggleDesktopDropdown }></div>
            </div>
            <div className='desktop-navbar-right-dropdown' style={{ visibility: "hidden" }}>
              <ul>
                <li>
                  { userProfile && userProfile.personaHandle && (
                    <b className='desktop-navbar-right-dropdown-link'>{ userProfile.personaHandle }</b>
                  )}
                </li>
                { userProfile && userProfile.personaHandle && (
                  <li>
                    <a href={`https://profiles.sansar.com/profiles/${userProfile.personaHandle}`} className='desktop-navbar-right-dropdown-link'>
                      { userProfile.personaHandle }
                    </a>
                  </li>
                )}
                {subMenuLinks.map((link) => (
                  <li key={link.title}>
                    <a href={link.url} className='desktop-navbar-right-dropdown-link'>
                      {link.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {!userIsLoggedIn && (
          <div className='desktop-navbar-right'>
            <div>
              <a href='https://account.sansar.com/register' className='desktop-navbar-right-dropdown-link-non-logged'>
                Sign up
              </a>
            </div>
            <div>
              <a href='https://login.sansar.com/login?redirect_url=https%3A%2F%2Fpayment.sansar.com' className='desktop-navbar-right-dropdown-link-non-logged'>
                Log in
              </a>
            </div>
          </div>
        )}
      </nav>

      <div className='mobile-controlls'>
        {userIsLoggedIn && (
          <div className='mobile-controlls-wrapper'>
            <div onClick={ showMobileNavbar } className='mobile-controlls-hamburguer'></div>
          </div>
        )}
        {!userIsLoggedIn && (
          <div className='mobile-controlls-wrapper'>
            <div style={{ height: "100%" }}>
              <a href='https://account.sansar.com/register' className='desktop-navbar-right-dropdown-link-non-logged'>
                Sign up
              </a>
            </div>
            <div style={{ height: "100%" }}>
              <a href='https://login.sansar.com/login?redirect_url=https%3A%2F%2Fpayment.sansar.com' className='desktop-navbar-right-dropdown-link-non-logged'>
                Log in
              </a>
            </div>
          </div>
        )}
      </div>

      <div className='mobile-navbar'>
        <div>
          {userIsLoggedIn && (
            <ul>
              <li className='mobile-navbar-head'>
                <div className='mobile-navbar-head-white mobile-navbar-head-ellipsis'>
                  { userProfile && userProfile.personaHandle && (
                    <b>{ userProfile.personaHandle }</b>
                  )}
                </div>
                <div>
                  <div onClick={ (e) => closeMobileNavbar(e) } className='mobile-navbar-head-close-button'>
                    <b>x</b>
                  </div>
                </div>
              </li>
              { userProfile && userProfile.personaHandle && (
                <li>
                  <a href={`https://profiles.sansar.com/profiles/${userProfile.personaHandle}`} className='navbar-link mobile-navbar-head-ellipsis'>
                    { userProfile.personaHandle }
                  </a>
                </li>
              )}
              <li>
                <hr/>
              </li>
              {subMenuLinks.map((link) => (
                <li key={link.title}>
                  <a href={link.url} className='navbar-link'>
                    {link.title}
                  </a>
                </li>
              ))}
              <li>
                <hr/>
              </li>
              {pageLinks.map((link) => (
                <li key={link.title}>
                  <a href={link.url} className='navbar-link'>
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </header>
  )
}

export default Header;