import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  setupUserConnectedAccount,
  getUserConnectedAccountVerified,
  deleteUserConnectedAccount
} from "../services/userConnectedAccount";
import { createWithdrawTransaction, getWithdrawTransactionsProcessing } from "../services/withdraw";
import { getPaymentMethodUsd } from "../services/wallet";
import { formatPrice } from "../utils/formatPrice";
import { countries } from "../utils/countries";
import { getTiliaWithdrawsUrl } from "../services/tilia";
import "../styles/withdraw.css";

const WITHDRAW_MINIMUM_AMOUNT_USD = 200;

class Withdraw extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      userBalanceUsd: null,
      requestAmountUsd: "",
      withdrawMinimumAmountUsd: WITHDRAW_MINIMUM_AMOUNT_USD,
      userConnectedAccountVerified: false,
      withdrawTransactionsProcessing: false,
      errorMessage: null,
      successMessage: null,
      countries,
      country: "",
    };
    this.handleRequestAmountChange = this.handleRequestAmountChange.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.setupConnectedAccount = this.setupConnectedAccount.bind(this);
    this.deleteConnectedAccount = this.deleteConnectedAccount.bind(this);
    this.goToTiliaWithdraws = this.goToTiliaWithdraws.bind(this);
    this.createWithdraw = this.createWithdraw.bind(this);
  }

  async componentDidMount() {
    this.setState({ loading: true });

    const queryParameters = new URLSearchParams(window.location.search);
    const amountUsdParam = this.cleanAmountUsd(queryParameters.get("amount_usd"));
    if (amountUsdParam) {
      this.setState({ requestAmountUsd: amountUsdParam });
    }

    await Promise.all([
      this.getUserWalletUsd(),
      this.getConnectedAccountVerified(),
      this.getWithdrawProcessing(),
    ]);

    this.setState({ loading: false });
  }

  async getUserWalletUsd() {
    const { accessToken } = this.props;
    // only fetch the user wallet USD if the user is logged in and the user wallet USD is not already fetched
    if (accessToken) {
      try {
        const userWalletUsd = await getPaymentMethodUsd(accessToken);
        // usd value is stored in cents
        this.setState({ userBalanceUsd: (userWalletUsd.wallet_balance / 100) });
      } catch (error) {
        // console.error(error.message);
      }
    }
  }

  async getConnectedAccountVerified() {
    const { accessToken } = this.props;
    if (accessToken) {
      try {
        const { verified } = await getUserConnectedAccountVerified(accessToken);
        this.setState({ userConnectedAccountVerified: verified });
      } catch (error) {
        // console.error(error.message);
      }
    }
  }

  async getWithdrawProcessing() {
    const { accessToken } = this.props;
    if (accessToken) {
      try {
        const { processing } = await getWithdrawTransactionsProcessing(accessToken);
        this.setState({ withdrawTransactionsProcessing: processing });
      } catch (error) {
        // console.error(error.message);
      }
    }
  }

  async setupConnectedAccount(event) {
    event.preventDefault();
    this.setState({ errorMessage: null, successMessage: null });
    const { accessToken } = this.props;
    const { country, userConnectedAccountVerified } = this.state;
    if (!country && !userConnectedAccountVerified) {
      this.setState({ errorMessage: "Please select a country for your account" });
      return;
    }
    if (accessToken) {
      this.setState({ loading: true });
      try {
        const { url } = await setupUserConnectedAccount(country, accessToken);
        if (url) window.location.href = url;
      } catch (error) {
        this.setState({
          errorMessage: "There was an error while creating your account, please get in touch with our support",
        });
        // console.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  async createWithdraw(event) {
    event.preventDefault();
    this.setState({ errorMessage: null, successMessage: null });
    const { accessToken } = this.props;
    const { requestAmountUsd } = this.state;
    if (!accessToken) {
      alert("Please log in to continue");
      return;
    }
    const amountUsd = Number(requestAmountUsd);
    if (!this.validateRequestAmountUsd(amountUsd)) {
      return;
    }
    this.setState({ loading: true });
    try {
      await createWithdrawTransaction({ amount_usd: amountUsd }, accessToken);
      // clear the input field and show a success message
      this.setState({ requestAmountUsd: "", successMessage: "Your withdraw request is being processed" });
      await this.getWithdrawProcessing();
    } catch (error) {
      this.setState({
        errorMessage: "There was an error while creating your withdraw request, please get in touch with our support",
      });
      // console.error(error.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async deleteConnectedAccount(event) {
    event.preventDefault();
    this.setState({ errorMessage: null, successMessage: null });
    const confirmation = window.confirm("Are you sure you want to delete your connected account? This is a destructive action, it cannot be undone");
    if (!confirmation) {
      return;
    }
    const { accessToken } = this.props;
    if (accessToken) {
      this.setState({ loading: true });
      try {
        await deleteUserConnectedAccount(accessToken);
        await Promise.all([
          this.getConnectedAccountVerified(),
          this.getWithdrawProcessing(),
        ]);
        this.setState({ successMessage: "Your account was successfully deleted" });
      } catch (error) {
        this.setState({
          errorMessage: "There was an error while deleting your account, please get in touch with our support",
        });
        // console.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  async goToTiliaWithdraws(event) {
    event.preventDefault();
    const { accessToken } = this.props;
    if (accessToken) {
      this.setState({ loading: true });
      try {
        const { url } = await getTiliaWithdrawsUrl(accessToken);
        if (url) window.location.href = url;
      } catch (error) {
        this.setState({
          errorMessage: "There was an error while retrieving your session link, please get in touch with our support",
        });
        // console.error(error.message);
      } finally {
        this.setState({ loading: false });
      }
    }
  }

  handleRequestAmountChange(event) {
    this.setState({ errorMessage: null, successMessage: null});
    const requestAmountUsd = this.cleanAmountUsd(event.target.value);
    if (requestAmountUsd) {
      this.setState({ requestAmountUsd });
    }
  }

  handleCountryChange(event) {
    this.setState({ errorMessage: null, successMessage: null });
    this.setState({ country: event.target.value });
  }

  validateRequestAmountUsd(amountUsd) {
    const { userBalanceUsd, withdrawMinimumAmountUsd } = this.state;
    let errorMessage = null;
    if (amountUsd <= 0) {
      errorMessage = "Please enter a valid amount";
    }
    if (amountUsd > userBalanceUsd) {
      errorMessage = "You cannot request more than your current balance";
    }
    if (amountUsd < withdrawMinimumAmountUsd) {
      errorMessage = "You cannot request less than the minimum amount";
    }
    if (errorMessage) {
      this.setState({ errorMessage });
      return false;
    }
    return true;
  }

  cleanAmountUsd(amountUsd) {
    if (! /^[1-9]{1}[0-9.]*/g.test(amountUsd)) {
      this.setState({ requestAmountUsd: "" });
      return false;
    }
    const cleanedAmount = amountUsd.replace(/[^0-9.]/g, "");
    if (!cleanedAmount || cleanedAmount <= 0) {
      this.setState({ requestAmountUsd: "" });
      return false;
    }
    if (cleanedAmount.includes(".")) {
      const splitAmount = cleanedAmount.split(".");
      if (splitAmount.length > 2) {
        return false; // we only allow one decimal point, no need to reset the amount here
      }
    }
    return cleanedAmount;
  }

  render() {
    const {
      handleRequestAmountChange,
      handleCountryChange,
      createWithdraw,
      setupConnectedAccount,
      deleteConnectedAccount,
    } = this;
    const {
      loading,
      userBalanceUsd,
      requestAmountUsd,
      withdrawMinimumAmountUsd,
      userConnectedAccountVerified,
      withdrawTransactionsProcessing,
      errorMessage,
      successMessage,
      countries,
      country,
    } = this.state;
    const { userIsLoggedIn } = this.props;

    return (
      <section>
        <h1>
          Process credit request
        </h1>
        <article className='wallet-balance-container'>
          <div className='mb-4'>
            <div>
              <p className='mb-2'>Current USD on your wallet</p>
            </div>
            <div className='wallet-balance-wrapper'>
              <p className='wallet-balance-title m-0'>
                USD Wallet balance
              </p>
              <p className='wallet-balance-usd m-0'>
                {formatPrice(userBalanceUsd)}
              </p>
            </div>
          </div>
          <div className='mb-4'>
            <p className='mt-2 mb-2'>How many USD do you want to withdraw?</p>
            <input disabled={loading || !userConnectedAccountVerified || !userIsLoggedIn} type='text' placeholder='USD amount'
              className='wallet-balance-input' value={requestAmountUsd} onChange={handleRequestAmountChange} />
            <p className='wallet-balance-minimum-info mt-2 mb-4'>
              The minimum amount to withdraw is {formatPrice(withdrawMinimumAmountUsd)}
            </p>
            {errorMessage && (
              <div className='wallet-balance-error-wrapper p-2 mt-4 mb-2'>
                <span>{errorMessage}</span>
              </div>
            )}
            {successMessage && (
              <div className='wallet-balance-success-wrapper p-2 mt-4 mb-2'>
                <span>{successMessage}</span>
              </div>
            )}
            {userIsLoggedIn && !userConnectedAccountVerified && (
              <div className='wallet-balance-info-wrapper p-2 mt-4 mb-2'>
                <span>
                  To to start receiving payouts, first you'll need<br/>
                  to set up your account through the onboarding
                </span>
              </div>
            )}
            {userIsLoggedIn && userConnectedAccountVerified && withdrawTransactionsProcessing && (
              <div className='wallet-balance-info-wrapper p-2 mt-4 mb-2'>
                <span>
                  You already have a request being processed,<br/>
                  please wait until it is completed
                </span>
              </div>
            )}
          </div>
          <div className='flex-row-wrapper'>
            {userIsLoggedIn && userConnectedAccountVerified && (
              <button disabled={loading || withdrawTransactionsProcessing} className='wallet-balance-button dark-blue' onClick={createWithdraw}>
                <b>Request</b>
              </button>
            )}
            {userIsLoggedIn && !userConnectedAccountVerified && (
              <select disabled={loading} className='wallet-balance-select' value={country} onChange={handleCountryChange}>
                <option value='' key='default' disabled readOnly>Country</option>
                {countries.map(({code, name}) => (
                  <option value={code} key={code}>{name}</option>
                ))}
              </select>
            )}
            {userIsLoggedIn && (
              <button disabled={loading} className='wallet-balance-button dark-gray' onClick={setupConnectedAccount}>
                <b>Set Up Account</b>
              </button>
            )}
          </div>
          {userIsLoggedIn && (
            <div className='flex-row-wrapper flex-center mt-16 mb-2'>
              <button disabled={loading || withdrawTransactionsProcessing} className='wallet-balance-link font-red'
                onClick={deleteConnectedAccount}
                title="An account can only be deleted if it doesn't have any pending requests">
                <b>Delete Account</b>
              </button>
            </div>
          )}
          {/* {userIsLoggedIn && ( */}
            {/* <div className='flex-row-wrapper flex-center mt-4 mb-2'>
              <button disabled={loading} className='wallet-balance-link'
                onClick={goToTiliaWithdraws}
                title="Go to Tilia withdraws page">
                <b>Go to Tilia withdraws page</b>
              </button>
            </div> */}
          {/* )} */}
        </article>
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    userIsLoggedIn: state.user.userIsLoggedIn,
    accessToken: state.user.accessToken,
  }
};

export default connect(mapStateToProps)(withRouter(Withdraw));
