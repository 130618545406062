import axios from "axios";
import { paymentApiUrl } from "./apis";

const axiosClient = axios.create({
  baseURL: paymentApiUrl,
  timeout: 5 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
});

const getTiliaWithdrawsUrl = async (accessToken) => {
  const response = await axiosClient.get("/v1/tilia/withdraws/link", {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
  });
  const session = response.data?.data;
  return session;
}

export {
  getTiliaWithdrawsUrl,
};
