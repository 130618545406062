import { combineReducers, configureStore } from "@reduxjs/toolkit"
import { AppReducer } from "../reducers/AppReducer";
import { UserReducer } from "../reducers/UserReducer";
import { CartReducer } from "../reducers/CartReducer";

export const initialState = {
  // app
  // user
  accessToken: null,
  refreshToken: null,
  userProfile: null,
  userBalance: 0,
  userIsFromCognito: false,
  userIsLoggedIn: false,
  // cart
  cartItems: [],
  totalPrice: 0,
}

const rootReducer = combineReducers({
  app: AppReducer,
  user: UserReducer,
  cart: CartReducer,
})

export const makeStore = preloadedState => {
  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
  })
  return store
}

export const store = makeStore();
