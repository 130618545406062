import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import BundleItem from "../components/BundleItem";
import {
  createSteamPaymentSession,
  createStripePaymentSession,
  createPaypalPaymentSession,
} from "../services/payment";
import { getBundleById } from "../services/bundles";
import { getUserBrowserInfo } from "../services/userBrowserInfo";
import { buildCartItemFromBundle } from "../utils/cartItem";
import "../styles/bundles.css";

class BundlePurchase extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      bundles: [],
      ipAddress: "",
    };
    this.handleCheckoutStripe = this.handleCheckoutStripe.bind(this);
    this.handleCheckoutPaypal = this.handleCheckoutPaypal.bind(this);
    this.handleCheckoutSteam = this.handleCheckoutSteam.bind(this);
    this.goToHomePage = this.goToHomePage.bind(this);
  }

  async componentDidMount() {
    const { cartItems } = this.props;

    this.setState({ loading: true });

    if (cartItems.length > 0) {
      this.setState({ bundles: [...cartItems] });
    } else {
      await this.getBundleById(this.props.match.params?.id);
    }
    await this.getIpAddress();

    this.setState({ loading: false });
  }

  goToHomePage() {
    this.props.history.push("/");
  }

  async getBundleById(bundleId) {
    const { dispatch } = this.props;

    if (!bundleId) {
      alert("No valid selected bundle was found");
      this.goToHomePage();
      return;
    }
    try {
      const bundle = await getBundleById(bundleId);
      if (!bundle) {
        alert("No valid selected bundle was found");
        this.goToHomePage();
        return;
      }

      dispatch({ type: "cartItems/clean" });
      const cartItem = buildCartItemFromBundle(bundle, 1);
      dispatch({ type: "cartItems/add", payload: cartItem });

      const totalPrice = parseFloat(bundle.price);
      dispatch({ type: "totalPrice/update", payload: totalPrice });

      this.setState({ bundles: [cartItem] });
    } catch (error) {
      // console.error(error.message);
    }
  }

  async getIpAddress() {
    if (!this.state.ipAddress) {
      try {
        const { ip } = await getUserBrowserInfo();
        this.setState({ ipAddress: ip });
      } catch (error) {
        // console.error(error.message);
      }
    }
  }

  async handleCheckoutSteam(event) {
    event.preventDefault();
    const { cartItems, accessToken } = this.props;
    const { ipAddress } = this.state;
    if (!accessToken) {
      alert("Please log in to continue");
      return;
    }
    this.setState({ loading: true });
    try {
      const payload = {
        ip_address: ipAddress,
        cart_items: cartItems.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        })),
      }
      const { url } = await createSteamPaymentSession(payload, accessToken);
      if (url) window.location.href = url;
    } catch (error) {
      // console.error(error.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleCheckoutStripe(event) {
    event.preventDefault();
    const { cartItems, accessToken } = this.props;
    if (!accessToken) {
      alert("Please log in to continue");
      return;
    }
    this.setState({ loading: true });
    try {
      const payload = {
        cart_items: cartItems.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        })),
      }
      const { url } = await createStripePaymentSession(payload, accessToken);
      if (url) window.location.href = url;
    } catch (error) {
      // console.error(error.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  async handleCheckoutPaypal(event) {
    event.preventDefault();
    const { cartItems, accessToken } = this.props;
    if (!accessToken) {
      alert("Please log in to continue");
      return;
    }
    this.setState({ loading: true });
    try {
      const payload = {
        cart_items: cartItems.map((item) => ({
          id: item.id,
          quantity: item.quantity,
        })),
      }
      const { url } = await createPaypalPaymentSession(payload, accessToken);
      if (url) window.location.href = url;
    } catch (error) {
      // console.error(error.message);
    } finally {
      this.setState({ loading: false });
    }
  }

  render() {
    const { handleCheckoutStripe, handleCheckoutPaypal, handleCheckoutSteam, goToHomePage } = this;
    const { userIsFromCognito, userIsLoggedIn } = this.props;
    const { loading, bundles } = this.state;

    return (
      <section>
        <h1>
          Complete your purchase
        </h1>
        <div className='bundle-purchase-info mt-8'>
          <div>
            <b>You are buying</b>
          </div>
          <div>
            <button onClick={() => goToHomePage()} className='go-back-button'>
              <b>Change amount</b>
            </button>
          </div>
        </div>
        <div className='bundles'>
          {bundles.map((bundle) => (
            <div key={bundle.id} className='bundle'>
              <BundleItem amount={bundle.sansar_dollar_amount} />
              <div>
                <b>{ bundle.formatted_price }</b>
              </div>
            </div>
          ))}
        </div>
        <div style={{ marginTop: "16px" }}>
          <b>Payment method</b>
        </div>
        {userIsLoggedIn && userIsFromCognito && (
          <div>
            <button disabled={loading} onClick={ handleCheckoutStripe } className='payment-checkout-button mt-2'>
              <span>Pay with Stripe</span>
              <img src='/stripe-logo.png' alt='Stripe logo' className='payment-checkout-button-payment-logo'></img>
            </button>
          </div>
        )}
        {userIsLoggedIn && userIsFromCognito && (
          <div>
            <button disabled={loading} onClick={ handleCheckoutPaypal } className='payment-checkout-button mt-2'>
              <span>Pay with Paypal</span>
              <img src='/paypal-logo.png' alt='Paypal logo' className='payment-checkout-button-payment-logo'></img>
            </button>
          </div>
        )}
        {userIsLoggedIn && !userIsFromCognito && (
          <div>
            <button disabled={loading} onClick={ handleCheckoutSteam } className='payment-checkout-button mt-2'>
              <span>Pay with Steam</span>
              <img src='/steam-logo.png' alt='Steam logo' className='payment-checkout-button-payment-logo'></img>
            </button>
          </div>
        )}
      </section>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart.cartItems,
    userIsFromCognito: state.user.userIsFromCognito,
    userIsLoggedIn: state.user.userIsLoggedIn,
    accessToken: state.user.accessToken,
  }
};

export default connect(mapStateToProps)(withRouter(BundlePurchase));
