import React from "react";
import { useHistory } from "react-router-dom";

const MessageTextPage = ({ messageText, pageName, pageRoute }) => {
  const history = useHistory();

  const goToPage = () => {
    history.push(pageRoute);
  }

  const redirectAfterSeconds = () => {
    setTimeout(() => {
      goToPage();
    }, 5000);
  }

  return (
    <section>
      <h2>
        {messageText}
      </h2>
      <p style={{ textAlign: "center" }}>
        You will be redirected to the {pageName} page in 5 seconds.<br/>
        Or click in the button below to go back now.
      </p>
      <div style={{ marginLeft: "auto", marginRight: "auto", width: "fit-content" }}>
        <button style={{ fontSize: "16px" }} onClick={ goToPage } className='go-back-button'>
          Go back to {pageName}
        </button>
      </div>
      {redirectAfterSeconds()}
    </section>
  );
};

export default MessageTextPage;
