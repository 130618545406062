import axios from "axios";
import { walletApiUrl } from "./apis";

const axiosClient = axios.create({
  baseURL: walletApiUrl,
  timeout: 5 * 1000,
});

const listPaymentMethods = async (accessToken) => {
  const response = await axiosClient.get("/v1/payment_methods", {
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`,
    },
  });
  const paymentMethods = response.data?.payload;
  return paymentMethods;
}

const getPaymentMethodUsd = async (accessToken) => {
  const paymentMethods = await listPaymentMethods(accessToken);
  return paymentMethods
    .find((pm) => pm.method_class === "registration");
}

export {
  listPaymentMethods,
  getPaymentMethodUsd,
};
