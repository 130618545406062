import axios from "axios";
import { buildDefaultHeaders, profileApiUrl } from "./apis";

const axiosClient = axios.create({
  baseURL: profileApiUrl,
  timeout: 5 * 1000,
});

const getProfile = async (accessToken) => {
  const response = await axiosClient.get("/v1/profile", {
    headers: buildDefaultHeaders(accessToken),
  });
  const profile = response.data?.data;
  return profile;
}

export {
  getProfile,
};
