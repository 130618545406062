import React from "react";
import MessageTextPage from "../components/MessageTextPage";

const BundlePurchaseCancel = () => {
  return (
    <MessageTextPage
      messageText='The payment was cancelled.'
      pageName='bundles'
      pageRoute='/'
    />
  );
};

export default BundlePurchaseCancel;
