import React from "react";
import MessageTextPage from "../components/MessageTextPage";

const BundlePurchaseFailure = () => {
  return (
    <MessageTextPage
      messageText='There was an error processing your payment, please try again.'
      pageName='bundles'
      pageRoute='/'
    />
  );
};

export default BundlePurchaseFailure;
