


import { initialState } from "../store/store";

export function CartReducer(state = initialState, action) {
  switch (action.type) {
    // cartItems
    case "cartItems/add": {
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      }
    }
    case "cartItems/clean": {
      return {
        ...state,
        cartItems: [],
      }
    }
    // totalPrice
    case "totalPrice/update": {
      return {
        ...state,
        totalPrice: action.payload,
      }
    }
    default:
      return state
  }
}
