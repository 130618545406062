const countries = [
  { "code": "AL", "name": "Albania" },
  { "code": "AG", "name": "Antigua and Barbuda" },
  { "code": "AR", "name": "Argentina" },
  { "code": "AM", "name": "Armenia" },
  { "code": "AU", "name": "Australia" },
  { "code": "AT", "name": "Austria" },
  { "code": "BS", "name": "Bahamas" },
  { "code": "BH", "name": "Bahrain" },
  { "code": "BE", "name": "Belgium" },
  { "code": "BO", "name": "Bolivia" },
  { "code": "BA", "name": "Bosnia and Herzegovina" },
  { "code": "BW", "name": "Botswana" },
  { "code": "BN", "name": "Brunei Darussalam" },
  { "code": "BG", "name": "Bulgaria" },
  { "code": "KH", "name": "Cambodia" },
  { "code": "CA", "name": "Canada" },
  { "code": "CL", "name": "Chile" },
  { "code": "CO", "name": "Colombia" },
  { "code": "CR", "name": "Costa Rica" },
  { "code": "CI", "name": "Côte d'Ivoire" },
  { "code": "HR", "name": "Croatia" },
  { "code": "CY", "name": "Cyprus" },
  { "code": "CZ", "name": "Czechia" },
  { "code": "DK", "name": "Denmark" },
  { "code": "DO", "name": "Dominican Republic" },
  { "code": "EC", "name": "Ecuador" },
  { "code": "EG", "name": "Egypt" },
  { "code": "SV", "name": "El Salvador" },
  { "code": "EE", "name": "Estonia" },
  { "code": "ET", "name": "Ethiopia" },
  { "code": "FI", "name": "Finland" },
  { "code": "FR", "name": "France" },
  { "code": "GM", "name": "Gambia" },
  { "code": "DE", "name": "Germany" },
  { "code": "GH", "name": "Ghana" },
  { "code": "GI", "name": "Gibraltar" },
  { "code": "GR", "name": "Greece" },
  { "code": "GT", "name": "Guatemala" },
  { "code": "GY", "name": "Guyana" },
  { "code": "HK", "name": "Hong Kong" },
  { "code": "HU", "name": "Hungary" },
  { "code": "IS", "name": "Iceland" },
  { "code": "IN", "name": "India" },
  { "code": "ID", "name": "Indonesia" },
  { "code": "IE", "name": "Ireland" },
  { "code": "IL", "name": "Israel" },
  { "code": "IT", "name": "Italy" },
  { "code": "JM", "name": "Jamaica" },
  { "code": "JP", "name": "Japan" },
  { "code": "JO", "name": "Jordan" },
  { "code": "KE", "name": "Kenya" },
  { "code": "KR", "name": "Korea" },
  { "code": "KW", "name": "Kuwait" },
  { "code": "LV", "name": "Latvia" },
  { "code": "LI", "name": "Liechtenstein" },
  { "code": "LT", "name": "Lithuania" },
  { "code": "LU", "name": "Luxembourg" },
  { "code": "MO", "name": "Macao" },
  { "code": "MG", "name": "Madagascar" },
  { "code": "MY", "name": "Malaysia" },
  { "code": "MT", "name": "Malta" },
  { "code": "MU", "name": "Mauritius" },
  { "code": "MX", "name": "Mexico" },
  { "code": "MD", "name": "Moldova" },
  { "code": "MC", "name": "Monaco" },
  { "code": "MN", "name": "Mongolia" },
  { "code": "MA", "name": "Morocco" },
  { "code": "NA", "name": "Namibia" },
  { "code": "NL", "name": "Netherlands" },
  { "code": "NZ", "name": "New Zealand" },
  { "code": "NG", "name": "Nigeria" },
  { "code": "NO", "name": "Norway" },
  { "code": "OM", "name": "Oman" },
  { "code": "PK", "name": "Pakistan" },
  { "code": "PA", "name": "Panama" },
  { "code": "PY", "name": "Paraguay" },
  { "code": "PE", "name": "Peru" },
  { "code": "PH", "name": "Philippines" },
  { "code": "PL", "name": "Poland" },
  { "code": "PT", "name": "Portugal" },
  { "code": "QA", "name": "Qatar" },
  { "code": "MK", "name": "Republic of North Macedonia" },
  { "code": "RO", "name": "Romania" },
  { "code": "RW", "name": "Rwanda" },
  { "code": "LC", "name": "Saint Lucia" },
  { "code": "SA", "name": "Saudi Arabia" },
  { "code": "SN", "name": "Senegal" },
  { "code": "RS", "name": "Serbia" },
  { "code": "SG", "name": "Singapore" },
  { "code": "SK", "name": "Slovakia" },
  { "code": "SI", "name": "Slovenia" },
  { "code": "ZA", "name": "South Africa" },
  { "code": "ES", "name": "Spain" },
  { "code": "LK", "name": "Sri Lanka" },
  { "code": "SE", "name": "Sweden" },
  { "code": "CH", "name": "Switzerland" },
  { "code": "TW", "name": "Taiwan" },
  { "code": "TH", "name": "Thailand" },
  { "code": "TT", "name": "Trinidad and Tobago" },
  { "code": "TN", "name": "Tunisia" },
  { "code": "TR", "name": "Turkey" },
  { "code": "AE", "name": "United Arab Emirates" },
  { "code": "GB", "name": "United Kingdom" },
  { "code": "TZ", "name": "United Republic of Tanzania" },
  { "code": "US", "name": "United States of America" },
  { "code": "UY", "name": "Uruguay" },
  { "code": "UZ", "name": "Uzbekistan" },
  { "code": "VN", "name": "Viet Nam" },
];

export {
  countries,
}
