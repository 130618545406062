import React from "react";
import MessageTextPage from "../components/MessageTextPage";

const BundlePurchaseSuccess = () => {
  return (
    <MessageTextPage
      messageText='Thanks for your purchase, your order is being processed.'
      pageName='bundles'
      pageRoute='/'
    />
  );
};

export default BundlePurchaseSuccess;
