import React from "react";
import MessageTextPage from "../components/MessageTextPage";

const WithdrawOnboardingRestart = () => {
  return (
    <MessageTextPage
      messageText="Your onboarding wasn't finished, you'll need to finish it to start receiving payouts."
      pageName='withdraw'
      pageRoute='/withdraw'
    />
  );
};

export default WithdrawOnboardingRestart;
