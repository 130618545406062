import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { CookiesProvider } from "react-cookie";
import { createRoot } from "react-dom/client"
import { Provider } from "react-redux"
// styles and global components
import "./styles/index.css";
import { store } from "./store/store";
import AppGlobalSettings from "./components/AppGlobalSettings";
import Header from "./components/Header";
import Footer from "./components/Footer";
// bundle pages
import Bundles from "./pages/Bundles";
import BundlePurchase from "./pages/BundlePurchase";
import BundlePurchaseSuccess from "./pages/BundlePurchaseSuccess";
import BundlePurchaseCancel from "./pages/BundlePurchaseCancel";
import BundlePurchaseFailure from "./pages/BundlePurchaseFailure";
// withdraw pages
import Withdraw from "./pages/Withdraw";
import WithdrawOnboardingRestart from "./pages/WithdrawOnboardingRestart";
import WithdrawOnboardingComplete from "./pages/WithdrawOnboardingComplete";

const container = document.getElementById("root")
if (container) {
  const root = createRoot(container)
  root.render(
    <Provider store={store}>
      <Router>
        <Header />
        <main>
          <CookiesProvider>
            <AppGlobalSettings />
            <Switch>
              <Route path="/purchase/success">
                <BundlePurchaseSuccess />
              </Route>
              <Route path="/purchase/cancel">
                <BundlePurchaseCancel />
              </Route>
              <Route path="/purchase/failure">
                <BundlePurchaseFailure />
              </Route>
              <Route path="/purchase/:id">
                <BundlePurchase />
              </Route>
              <Route path="/withdraw/onboarding/restart">
                <WithdrawOnboardingRestart />
              </Route>
              <Route path="/withdraw/onboarding/complete">
                <WithdrawOnboardingComplete />
              </Route>
              <Route path="/withdraw">
                <Withdraw />
              </Route>
              <Route path="/">
                <Bundles />
              </Route>
            </Switch>
          </CookiesProvider>
        </main>
        <Footer />
      </Router>
    </Provider>
  );
} else {
  throw new Error("Root element with ID 'root' was not found in the document. Ensure there is a corresponding HTML element with the ID 'root' in your HTML file.")
}
