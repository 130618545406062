import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "../styles/footer.css";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer>
      <div className='footer-top'>
        <ul>
          <li>
            <h6>Sansar</h6>
          </li>
          <li><a href='https://atlas.sansar.com'>Worlds</a></li>
          <li><a href='https://events.sansar.com'>Events</a></li>
          <li><a href='https://store.sansar.com'>Store</a></li>
          <li><a href='https://www.sansar.com/download'>Download</a></li>
          <li><a href='https://account.sansar.com/subscriptions'>Subscribe</a></li>
          <li><a href='https://discord.com/invite/sansarofficial'>Community</a></li>
          <li><a href='https://docs.sansar.com/'>Docs</a></li>
        </ul>
        <ul>
          <li>
            <h6>Company</h6>
          </li>
          <li><a href='https://www.sansar.com/terms-of-service' target='_blank' rel='noopener noreferrer'>Terms of service</a></li>
          <li><a href='https://www.sansar.com/privacy' target='_blank' rel='noopener noreferrer'>Privacy</a></li>
          <li><a href='https://tv.sansar.com' target='_blank' rel='noopener noreferrer'>Sansar TV</a></li>
          <li><a href='https://www.sansar.com/contact-us'>Contact Us</a>
          </li>
          <li><a href='https://www.sansar.com/about-us'>About Us</a></li>
        </ul>
        <ul>
          <li>
            <h6>Follow us</h6>
          </li>
          <li><a href='https://www.facebook.com/SansarOfficial/' target='_blank' rel='noopener noreferrer'>Facebook</a></li>
          <li><a href='https://twitter.com/sansarofficial' target='_blank' rel='noopener noreferrer'>Twitter</a></li>
          <li><a href='https://www.youtube.com/sansar' target='_blank' rel='noopener noreferrer'>YouTube</a></li>
          <li><a href='https://www.flickr.com/photos/sansarofficial' target='_blank' rel='noopener noreferrer'>Flickr</a></li>
          <li><a href='https://www.instagram.com/sansarofficial/' target='_blank' rel='noopener noreferrer'>Instagram</a></li>
          <li><a href='https://discord.com/invite/sansarofficial'>Discord</a></li>
        </ul>
      </div>
      <div className='footer-bottom'>
        © { currentYear } Sansar. All Rights Reserved
      </div>
    </footer>
  )
}

const mapStateToProps = (state) => {
  return {}
};

export default connect(mapStateToProps)(withRouter(Footer));
